export default {
  "error": {
    "100001": "Verification incorrect",
    "100002": "Account password incorrect",
    "100003": "Entity doesn't exist",
    "100004": "Account type inconsistent",
    "100005": "Remote API calling error",
    "100006": "The name already exist",
    "100007": "User is not found",
    "100008": "Verification code out of limitation",
    "100009": "Token acquiring error",
    "100010": "Verification code incorrect",
    "100011": "Amending ID doesn't exist",
    "100012": "Data error",
    "100013": "The code already exist",
    "100014": "Unallowed",
    "100015": "Login expired",
    "100016": "Cell phone number doesn't exist",
    "100017": "Image saved address incorrect",
    "100018": "Invalid timezone",
    "100019": "The company principal cannot be deleted, please change the principal first if you need to delete it.",
    "100020": "用户不是管理员",
    "100021": "参数是必须的",
    "100022": "无效时间戳",
    "100023": "无效的配置",
    "100024": "手机号已经存在",
    "100025": "角色已经存在",
    "100026": "pdf宽高比不符合",
    "100027": "国际区号与国家信息不匹配",
    "100028": "邮编不能为空",
    "100029": "详细地址不能为空",
    "100030": "原密码输入不正确",
    "100031": "电话号码格式错误",
    "100032": "当联系电话不为空时,区号所属国家必填",
    "100401": "Unauthorization error",
    "100403": "Declined for without access authorization",
    "100404": "Error not found",
    "100429": "请求过快,请稍后重试",
    "100500": "Server error",
    "110001": "Email already exist",
    "110002": "Product name already exist",
    "110003": "Product code already exist",
    "110004": "Product Id doesn't exist",
    "110005": "Verification info of the product packing already exist",
    "110006": "This currency account of user already exist",
    "110007": "Balance accound does not exist",
    "110008": "Tenant code reduplicate",
    "110009": "Cell phone number already exist",
    "110010": "Shipping features empty",
    "110011": "Identification content of product label already exist",
    "110012": "Customs info incompleted",
    "110013": "Default customs info is required",
    "110014": "Default customs info incompleted",
    "110015": "Name of sales source already exist",
    "110016": "Statement of account has been settled",
    "110017": "Payment settled statement already exist",
    "110018": "Balance account does not exist",
    "110019": "Name of contact address already exist",
    "110020": "Finance Account Exception",
    "110021": "Recharge can't be re-submitted because this serial number had been recharged or it's in processing.",
    "110022": "The image verification failed.",
    "110023": "Account has been bound to tenant.",
    "110024": "Account hasn't been bound to tenant.",
    "110025": "The balance credit is not enough.",
    "110026": "The recharge order has been processed and cannot be repeated.",
    "110027": "Exceptional Recharge Status",
    "110028": "Account audit result is not Failed.",
    "110029": "The currency does not exist.",
    "110030": "Currency not found",
    "110031": "Please upload product label.",
    "110032": "Payment exception",
    "110033": "Balance not enough",
    "110034": "Credit not enough",
    "110035": "Can not repeat paying.",
    "110036": "Can not repeat refunding.",
    "110037": "Refund failed",
    "110038": "Mainland China cell phone number supported only",
    "110039": "Account linked to this phone number does not exist.",
    "110040": "Recharge amount must be more than zero.",
    "110041": "No ShopId",
    "110042": "No payment receipt account info, please contact the administrator.",
    "110043": "Only one 'To be confirmed' allowed for each currency.",
    "110044": "Maximum recharge amount is 1,000,000.00",
    "110045": "Limit of overdraft unchanged.",
    "110046": "Overdue",
    "110047": "Loaded label identification code already exist.",
    "110048": "Company exist under current account grade class.",
    "110049": "The maximum time frame is 31 days.",
    "110050": "Product Not Found",
    "110051": "The company has been frozen.",
    "110052": "Refund amount must be more than 0",
    "110053": "Company name already exist.",
    "110054": "Company dose not exist.",
    "110055": "联系电话不匹配",
    "110056": "通用联系人数据处理中",
    "110057": "产品货号不符合规则",
    "110058": "包含电池电池信息必填",
    "110059": "运输特征信息错误",
    "110060": "电池类型不存在",
    "110061": "电池成分不存在",
    "110062": "角色不存在",
    "110063": "当前充值状态不可提交",
    "110064": "当前充值状态不可取消",
    "110065": "不是有效的Excel文件",
    "110066": "当前产品已被使用不允许删除",
    "120001": "Warehouse name already exist.",
    "120002": "Name of location type already exist",
    "120003": "Area name already exist.",
    "120004": "Location sequence number of area already exist.",
    "120005": "Sub-area or location available in this area.",
    "120006": "Outbound plan name already exist.",
    "120007": "Inbound plan name already exist.",
    "120008": "Warehouse code already exist.",
    "120009": "Fee code already exist.",
    "120010": "Warehouse does not exist.",
    "120011": "Allocation plan does not exist",
    "120012": "Carton info cannot be empty.",
    "120013": "Quantity setting incorrectly.",
    "120014": "Setting quantity exceed maximum limitation.",
    "120015": "Order info of wave task does not exist.",
    "120016": "This location is occupied.",
    "120017": "Update cannot be repeated when investigation status is investigation completed.",
    "120018": "Exceed the maximum allowed in this location.",
    "120019": "This location is occupied by products of other plan.",
    "120020": "This location hasn't been bound to any warehouse area.",
    "120021": "This location is not inbound area.",
    "120022": "This location is not outbound area.",
    "120023": "This location is not handing over area.",
    "120024": "Label handling result is required when the warehouse is distribution warehouse.",
    "120025": "Info modification is not allowed with current inbound status.",
    "120026": "Tracking number cannot be filled in with current inbound status.",
    "120027": "Plan cannot be confirmed with current inbound status.",
    "120028": "Shipping is not available with current inbound status.",
    "120029": "Abnomral status, cannot proceed with current inbound status.",
    "120030": "Stocking in is not allowed with current inbound status.",
    "120031": "Inbound error status in stock warehouse can only be Overtime on receiving.",
    "120032": "If inbound error status is overtime, client can only operate with Distribution Omission or Request Investigation.",
    "120033": "Incorrect tracking number.",
    "120034": "Dimension pictures and verifying pictures for stocking in are required.",
    "120035": "Pictures of product shortage are required when product shortage found for stocking in.",
    "120036": "Pictures of incorrect products are required when incorrect products found for stocking in.",
    "120037": "General stocking in if inbound quantity no less than packaging quantity.",
    "120038": "If outbound type is Amazon, Amazon transmission number and tracking number are required.",
    "120039": "If outbound type is allocating/transferring to local warehouse, warehouse info is required.",
    "120040": "If outbound type is other address, receiver info is required.",
    "120041": "If outbound type is not transferring to local warehouse, pallet service is required.",
    "120042": "If outbound type is transferring to local warehouse, product label handling is required.",
    "120043": "If outbound type is Amazon/other address, carton label is required.",
    "120044": "If outbound type is not transferring to local warehouse, payment method is required.",
    "120045": "If handing over type is self pick up, self pick up code is required.",
    "120046": "If handing over type is SE Logistics, logistics info is required.",
    "120047": "Info modification is not allowed with current outbound status.",
    "120048": "Cannot confirm with current outbound status.",
    "120049": "Labeling is not allowed with current outbound status.",
    "120050": "Cannot scan with current outbound status.",
    "120051": "Cannot reset scan with current stocking out status.",
    "120052": "Stocking out is not allowed with current outbound status.",
    "120053": "Handing over is not allowed with current outbound status.",
    "120054": "Same carton number already exist in current outbound plan.",
    "120055": "Stocking out is not allowed due to unscanned products.",
    "120056": "Please enter correct user's self pick up code when handing over type is self pick up.",
    "120057": "Stocking out is not allowed due to unhandled products.",
    "120058": "Wave task does not exist.",
    "120059": "Wave task has been taken.",
    "120060": "Carton number has been used in other plan.",
    "120061": "This carton was not assigned to current warehouse.",
    "120062": "Wave task can not be aborted with current status.",
    "120063": "Wave task hasn't been taken.",
    "120064": "Picking carton info doesn't exist.",
    "120065": "Please assign the location type with picking carton.",
    "120066": "Detailed info of wave task doesn't exist.",
    "120067": "Inventory inadequate in warehouse.",
    "120068": "Incorrect location.",
    "120069": "Incorrect target.",
    "120070": "Product picking completed.",
    "120071": "Product doesn't exist at inventory location.",
    "120072": "Picking task doesn't exist.",
    "120073": "Outbound task can't be taken with current outbound status or has been taken.",
    "120074": "Task of this outbound plan hasn't been taken.",
    "120075": "Picking task uncompleted.",
    "120076": "Can not stocking in again with current outbound status.",
    "120077": "Unavailable picking carton, has been assigned with wave task.",
    "120078": "No sorted carton has been assigned with wave task.",
    "120079": "Picking carton has not been assigned with wave task.",
    "120080": "Incorrect quantity of sorted boxes.",
    "120081": "Sorted product unavailable, there is order info for it.",
    "120082": "Task of sorting doesn't exist.",
    "120083": "Sorting completed for product.",
    "120084": "No enough product for picking carton.",
    "120085": "Task of sorting uncompleted.",
    "120086": "Incorrect type of sorted box area.",
    "120087": "Packing & outbound task doesn't exist.",
    "120088": "Target incorrect.",
    "120089": "Scanning completed for product.",
    "120090": "Product verifying uncompleted for packing & outbound task.",
    "120091": "Target location is not transferred carton.",
    "120092": "Location hasn't been assigned to any area.",
    "120093": "Quantity of transferred products more than required quantity for task.",
    "120094": "No transfer task for transferred products.",
    "120095": "Product inventory inadequate.",
    "120096": "No inventory for product.",
    "120097": "This location can't be found in warehouse.",
    "120098": "Warehosue ID is required.",
    "120099": "Sequence number exceed limitation.",
    "120100": "Same warehouse type.",
    "120101": "Allocation plan doesn't exist.",
    "120102": "Distribution warehouse info doesn't exist.",
    "120103": "Stocking warehouse info doesn't exist.",
    "120104": "Distribution plan info doesn't exist.",
    "120105": "Distribution plan details doesn't exist.",
    "120106": "Carton info of distribution plan doesn't exist.",
    "120107": "Product parameters incorrect.",
    "120108": "Location info incorrect.",
    "120109": "Warehouse type incorrect.",
    "120110": "Area type of location unavailable(inactive).",
    "120111": "Product info doesn't exist.",
    "120112": "Packing info doesn't exist.",
    "120113": "Order status has been changed",
    "120114": "Product doesn't exist in warehouse",
    "120115": "Planned packing quantity exceed maximum limitation",
    "120116": "Planned packing quantity can't be empty.",
    "120117": "Same product found in this inbound plan.",
    "120118": "Outbound operating platform doesn't exist.",
    "120119": "If inbound error status is incorrect product, client can't operate with Distribution Omission and Request Investigation.",
    "120120": "If client's operation is Change SESKU, product must be chosen.",
    "120121": "If client's operation is Request Investigation, investigation details is required.",
    "120122": "Please choose Inbound Plan.",
    "120123": "Serial number of Inbound Plan doesn't exist.",
    "120124": "PIN code is incorrect for current operator.",
    "120125": "General specs of stocking in is incorrect.",
    "120126": "Invalid warehouse ID.",
    "120127": "Delivery address can not be empty.",
    "120128": "No anomaly for the verification of this plan, no need to recalculate.",
    "120129": "Sesku incorrect for this plan.",
    "120130": "Stocking location should not include outbound operating platform location.",
    "120131": "Incorrect area.",
    "120132": "This location/user has been assigned with other scanning gate.",
    "120133": "Carton number of plan is invalid.",
    "120134": "Shipping plan doesn't exist or status has been changed.",
    "120135": "Shipping plan details doesn't exist.",
    "120136": "Shipping plan cartons haven't been completely scanned.",
    "120137": "Shipping plan cartons haven't been completely received.",
    "120138": "Shipping plan cartons info doesn't exist in warehouse.",
    "120139": "Shipping plan cartons info doesn't exist.",
    "120140": "Verified length/width/height are required for shipping plan cartons.",
    "120141": "Verification of shipping plan carton exceed the deviation threshold value.",
    "120142": "If stocking in with quantify shortage, received quantify must be less than packing quantity but more than zero.",
    "120143": "Logistics center warehouse can't find the shipping plan.",
    "120144": "Shipping plan details doesn't exist or status has been changed.",
    "120145": "This location code already exist in the bound area.",
    "120146": "Shipping plan logistics info doesn't exist.",
    "120147": "This outbound plan was not assigned to current operator.",
    "120148": "Storage fee settings doesn't exist.",
    "120149": "SE Logistics is not supported for the warehouse/address.",
    "120150": "Target warehouse for stocking out can not be the outbound warehouse.",
    "120151": "Picking carton error, wave task has been assigned with picking carton.",
    "120152": "Carton size(length/width/height) bigger than pallet maximum size(length/width/height).",
    "120153": "Shipping plan can't be created due to no address for selected warehouse.",
    "120154": "Packing & outbound task has been assigned to a outbound operating platform.",
    "120155": "Employee's cell phone number already exist.",
    "120156": "Shipping plan can not be edited with current status.",
    "120157": "New location code incorrect.",
    "120158": "Address of distribution warehouse doesn't exist.",
    "120159": "Delivery address doesn't exist.",
    "120160": "Shipping plan can't be created due to no address for selected warehouse.",
    "120161": "Delivery address of shipping plan is incompleted.",
    "120162": "Pick Up on door has been assigned to shipping plan, contact person and contact number are required.",
    "120163": "Packaging volume or volume-weight is inadequate.",
    "120164": "Shipping plan name already exist.",
    "120165": "Same shipping plan name, no need to modify.",
    "120166": "No verified fee for shipping plan, unpayable.",
    "120167": "Volume or weight of shipping plan exceeding standard.",
    "120168": "Shipping address of shipping plan doesn't exist.",
    "120169": "Pick Up Branch doesn't exist.",
    "120170": "Only one currency allowed for the quotation of shipping plan customs.",
    "120171": "Shipping plan doesn't exist.",
    "120172": "Shipping plan has been paid.",
    "120173": "Token or machine code transferred from scanning gate incorrect.",
    "120174": "Warehouse info for scanning gate not found.",
    "120175": "Outbound operating platform can not be assigned to while product collecting or stocking out.",
    "120176": "Pick Up Task of Shipping Plan doesn't exist.",
    "120177": "Employee info not found.",
    "120178": "Pick Up status of shipping plan has been updated.",
    "120179": "Pick Up arrival time of shipping plan is required.",
    "120180": "Pick Up estimated arrival time of shipping plan hasn't been set.",
    "120181": "Pick Up estimated arrival time of shipping plan can not be earlier than current time.",
    "120182": "Pick Up on door of shipping plan is uncompleted.",
    "120183": "Shipping plan has been refunded",
    "120184": "Shipping plan hasn't been paid, refund unavailable.",
    "120185": "Shipping plan automatically created for stocking in/out can not be deleted.",
    "120186": "Product inventory in this location is inadequate.",
    "120187": "Distribution order doesn't exist.",
    "120188": "This location is not allocation operating platform.",
    "120189": "This location is not Collect Carton.",
    "120190": "This Collect Carton was not assigned to this allocation operating platform.",
    "120191": "Allocation operating platform has not been created.",
    "120192": "Packaging quantity of allocation plan incorrect.",
    "120193": "Carton label hasn't been printed for this carton.",
    "120194": "There is incompletely packaged carton in this plan.",
    "120195": "Collect Task doesn't exist.",
    "120196": "Collect Task details doesn't exist.",
    "120197": "Collect Task has been taken.",
    "120198": "Collect Task hasn't been taken.",
    "120199": "Move the carton into unsupported area.",
    "120200": "Move the carton out from unsupported area.",
    "120201": "Collecting Task Completed",
    "120202": "The carton has been assigned to other Collecting Task.",
    "120203": "The carton is not found.",
    "120204": "The area type of collecting carton is incorrect.",
    "120205": "No carton has been assigned to the Collecting Task.",
    "120206": "The location info of Collecting Task does not exist.",
    "120207": "This product has been collected.",
    "120208": "The volume of package allocated to Storage Plan is too small.",
    "120209": "Collecting Task uncompleted.",
    "120210": "Sub-department exist under this department.",
    "120211": "Employee exist under this department.",
    "120212": "Payment record doesn't exist.",
    "120213": "Logistics unsupported for delivery address.",
    "120214": "User has paied for the Shipping Plan.",
    "120215": "Product doesn't exist at current location.",
    "120216": "No product added for the Shipping Plan.",
    "120217": "Product of the Shipping Plan has been shipped out.",
    "120218": "Product of the Shipping Plan has been delivered.",
    "120219": "Shipping Plan has been cancelled.",
    "120220": "Tracking Number is required for the Shipping Plan.",
    "120221": "Tracking Number of the Shipping Plan is incorrect.",
    "120222": "There is minimum weight limitation for pick up on door of Shipping Plan.",
    "120223": "When the Outbound Type is MercadoLibre, SELLER ID and Inbound Number cannot be empty.",
    "120224": "Logistics Charges unavailable due to carton dimension or weight oversize of Shipping Plan.",
    "120225": "FCL Appointment not found.",
    "120226": "Shipping Plan cannot be deleted currently.",
    "120227": "Inbound Plan not found.",
    "120228": "Outbound Plan not found.",
    "120229": "Location not found.",
    "120230": "Area not found.",
    "120231": "Disposal Task not found.",
    "120232": "Gate not found.",
    "120233": "Storage Fee not found.",
    "120234": "Handling Fee not found.",
    "120235": "Package Fee not found.",
    "120236": "Shippping Plan has been proceeded, Inbound Plan cannot be cancelled.",
    "120237": "Shippping Plan has been proceeded, Outbound Plan cannot be cancelled.",
    "120238": "Logistics Charges is charged by Chargeable Weight,Chargeable Volume can only be empty.",
    "120239": "Logistics Charges is charged by Chargeable Volume,Chargeable Weight can only be empty.",
    "120240": "If the Hand Over Type is Self Pick Up, Pick Up Type is required.",
    "120241": "If the Pick Up Type is Truck, Pick Up code is required.",
    "120242": "Shipping Order Number already exist for this client.",
    "120243": "Destination Type unsupported.",
    "120244": "If the Destination Type is Other Address, Receiver Information is required.",
    "120245": "If the Destination Type is MercadoLibre, Warehouse Id is required.",
    "120246": "If the Destination Type is FBA, Warehouse Id is required.",
    "120247": "If the Destination Type is SE Warehouse, Warehouse Id is required.",
    "120248": "Shipping Order not found.",
    "120249": "Supplier Logistics Solution is unavailable for current Shipping Order status.",
    "120250": "Shipping Order cannot be cancelled with current status.",
    "120251": "Pre-entry is unavailable for current Shipping Order status.",
    "120252": "To Be Online is unavailable for current Shipping Order status.",
    "120253": "To Be Received With Signature is unavailable for current Shipping Order status.",
    "120254": "Received With Signature is unavailable for current Shipping Order status.",
    "120255": "Handling Type of current Shipping Order is unsupported.",
    "120256": "Beyond maximum available carton quantity for outbound.",
    "120257": "Return details cannot be empty.",
    "120258": "Appearance Inspection must be chosen when Function Inspection is chosen.",
    "120259": "Return package number already exist.",
    "120260": "Return Plan does not exist.",
    "120261": "Return Plan status has been changed.",
    "120262": "Picture of Customs Clearance Type of Shipping Plan is required.",
    "120263": "Working Platform code is incorrect, please assign Inbound Area.",
    "120264": "Return Plan Completed",
    "120265": "Return Plan Cancelled",
    "120266": "Recognizable Label of Return Plan product unfound.",
    "120267": "Fees configuration does not exist.",
    "120268": "Inbound of Return Plan uncompleted.",
    "120269": "Product of Return Plan has not been stocked in.",
    "120270": "Verified length/width/height of Return Plan product is required.",
    "120271": "Return Plan product has not been verified.",
    "120272": "Return Plan has been assigned to other working platform.",
    "120273": "Refund unavailable for current status of Return Plan.",
    "120274": "Label Fee has not been set for Return Plan.",
    "120275": "Return Service Fee has not been set for Return Plan.",
    "120276": "Standard Inspection Fee has not been set for Return Plan.",
    "120277": "Disposal Fee has not been set for Return Plan.",
    "120278": "Function Inspection Surcharge has not been set for Return Plan.",
    "120279": "Modification unavailable for current status.",
    "120280": "Product FNSKU cannot be repeated for current Return Plan.",
    "120281": "Disposal unavailable since Standard Inspection was not chosen for the Return Plan.",
    "120282": "Only unqualified product can be disposed for the Return Plan.",
    "120283": "Warehouse has been suspended.",
    "120284": "Disposal Fee must be configured for warehouse when the Inspection is chosen.",
    "120285": "Current Shipping Plan cannot be cancelled.",
    "120286": "Carton Label Fee has not been set for Distribution Order.",
    "120287": "Outbound Handling Fee has not been set for Distribution Order.",
    "120288": "Handling Fee has not been set for Distribution Order.",
    "120289": "Package Material is required for Distribution Order.",
    "120290": "Product Quantity is required for Distribution Order.",
    "120291": "Allocation Plan is unsupported currently.",
    "120292": "Outbound Handling is unsupported for current warehouse.",
    "120293": "The Address Type is unsupported for current channel.",
    "120294": "Inventory insufficient for other locations.",
    "120295": "Sorting uncompleted.",
    "120296": "Sorting Box has not been enabled.",
    "120297": "Warehouse location unavailable.",
    "120298": "Order Status unmatched for Packing and Outbound Task.",
    "120299": "Manual Recognization Fee must be configured for the warehouse when Manual Recognization is chosen.",
    "120300": "This product is unsupported for the packing.",
    "120301": "Manual Recognization Fee must be configured for the warehouse when Manual Recognization is chosen.",
    "120302": "Completing Collecting Task failed.",
    "120303": "Cancelling Distribution Order failed.",
    "120304": "Self Pick Up Fee has not been configured for this warehouse.",
    "120305": "3rd Party Waybill is required for Distribution Order.",
    "120306": "3rd Party Waybill is required for Distribution Order.",
    "120307": "Including products does not belong to current user.",
    "120308": "This carton does not belong to the user.",
    "120309": "Outbound Handling Fee of this warehouse has not been configured yet.",
    "120310": "Label Handling Fee of this warehouse has not been configured yet.",
    "120311": "Pallet Handling Fee of this warehouse has not been configured yet.",
    "120312": "Packing Fee of this warehouse has not been configured yet.",
    "120313": "File format of 3rd Party Waybill incorrect.",
    "120314": "Return Plan can be modified only one time.",
    "120315": "Tracking Number of returned package does not comply with the rules.",
    "120316": "Sorting Completed",
    "120317": "Sorting Task Completed",
    "120318": "Suspension is not allowed with current status of the Sorting Task.",
    "120319": "Creating order of Wave Task failed.",
    "120320": "It's been shipped out for the Packing and Outbound Task.",
    "120321": "Waybill was not printed or Tracking Number was not input.",
    "120322": "Off-line Logistics Waybill is not allowed for 3rd Party Waybill.",
    "120323": "请选择派送方式",
    "120324": "配送订单已取消",
    "120325": "运单处理中",
    "120326": "未找到该计划对应的箱",
    "120327": "入库计划处理中",
    "120328": "出库计划处理中",
    "120329": "调拨计划处理中",
    "120330": "闸机处理中",
    "120331": "仓库库位处理中",
    "120332": "仓库库位类型处理中",
    "120333": "仓库区域处理中",
    "120334": "仓库库位任务处理中",
    "120335": "入库请求调查处理中",
    "120336": "箱信息处理中",
    "120337": "配送订单处理中",
    "120338": "支付失败不创建退货计划",
    "120339": "仓库对公司的折扣配置已经存在",
    "120340": "运输计划该状态不能收货",
    "120341": "运输计划未开始收货",
    "120342": "运输计划当前状态不可退款",
    "120343": "运输计划添加到付异常",
    "120344": "运输计划不支持物流到付",
    "120345": "该状态不允许收货",
    "120346": "运输计划箱已取消",
    "120437": "待审核或者待支付状态才允许更新箱尺寸",
    "120439": "分拣未完成",
    "120440": "已申请退货",
    "120441": "配送订单用户未申请退货",
    "120442": "包装袋仅支持装一个产品",
    "120443": "配送订单包装材料体积或容重不足",
    "120444": "派送方式不支持",
    "120445": "物流方式不支持",
    "120446": "出库类型不支持",
    "120447": "目的地类型不支持",
    "120448": "运输特征不支持",
    "120449": "标签费未设定",
    "120450": "箱标费未设定",
    "120451": "操作费未设定",
    "120452": "归集费未设定",
    "120453": "卸货费未设置",
    "120454": "爽约金未设置",
    "120455": "请求调查证明材料必须的",
    "120456": "请求调查证明材料最多3张",
    "120457": "退货来源不支持",
    "120458": "可识别码类型错误",
    "120459": "仓库退货存储区域处理中",
    "120460": "当前退货暂存区域存在包裹",
    "120461": "当前退货暂存区域存在子区域",
    "120462": "退货暂存区未找到",
    "120463": "退货暂存区不能被使用",
    "120464": "暂存费未设定",
    "120465": "暂存信息不存在",
    "120466": "超过15日无匹配的退货信息才能被销毁",
    "120468": "订单不存在或状态已改变",
    "120469": "无匹配的退货暂存才能移库",
    "120470": "有退货计划不能被销毁",
    "120471": "退货工作台不存在",
    "120472": "已匹配暂存区包裹，不可修改",
    "120473": "当前包裹已销毁",
    "120474": "退货记录不存在",
    "120475": "运单取消异常",
    "120476": "该状态不允许退款或赔付",
    "120477": "订已完成退款",
    "120478": "已完成赔付",
    "120479": "操作数量必须大于等于1",
    "120480": "卡车费用不存在",
    "120481": "报价类型不存在",
    "120482": "服务等级不存在",
    "120483": "附加选项不存在",
    "120484": "当报价类型为WG时服务等级必须的",
    "120485": "运输计划处理中",
    "120486": "不支持ExceptionInvestigationResult",
    "120487": "区域不存在",
    "120489": "入库计划已处理不能取消",
    "120490": "调拨计划不存在或者状态已改变",
    "120491": "调拨计划处理中",
    "120492": "订单允许最大数量",
    "120493": "包装材料不存在",
    "120494": "包装材料不可用",
    "120495": "物流方案不支持",
    "120496": "合并包装费未设置",
    "120497": "合并包装费不支持",
    "120498": "包装材料类型不支持",
    "120499": "电话所属国家不存在",
    "130001": "Name already exist.",
    "130002": "Currency code already exist.",
    "130003": "Over 20 files uploaded in one time.",
    "130004": "Single file size bigger than 5MB",
    "130005": "Label bar code analyzing failed.",
    "130006": "Province doesn't exist.",
    "130007": "Country doesn't exist.",
    "130008": "City doesn't exist.",
    "130009": "County doesn't exist.",
    "130010": "Invalid Currency",
    "130011": "解析条码格式不支持",
    "130012": "费用配置币种已存在",
    "130013": "费用配置不存在",
    "130014": "上传文件格式不支持",
    "130015": "上传文件最大50MB",
    "130016": "Base64文件格式错误",
    "130017": "解析条码失败",
    "130018": "电话区号所属国家不存在",
    "130019": "每次最多上传100条",
    "140000": "Logistics solution not found.",
    "140001": "Country of Logistics solution address incorrect.",
    "140002": "Province/State of Logistics solution address incorrect.",
    "140003": "City of Logistics solution address incorrect.",
    "140004": "County of Logistics solution address incorrect.",
    "140005": "Charges template not found.",
    "140006": "Charges template settings can not be empty.",
    "140007": "Starting weight can not be less than zero.",
    "140008": "Terminating weight must be more than starting weight.",
    "140009": "First weight charge can not be less than zero.",
    "140010": "Additional weight unit must be more than zero.",
    "140011": "Additional weight unit price can not be less than zero.",
    "140012": "Weight section must be serial.",
    "140013": "Weight can not be less than zero.",
    "140014": "Charges can not be less than zero.",
    "140015": "Billing method incorrect.",
    "140016": "Quantity can not be less than zero.",
    "140017": "Quantity of each page must be more than zero.",
    "140018": "Weight type incorrect.",
    "140019": "Starting length can not be less than zero.",
    "140020": "Terminating length must be more than starting weight.",
    "140021": "Surcharge billing method incorrect.",
    "140022": "Interval must be more than zero.",
    "140023": "Surcharge configurations not found.",
    "140024": "Configured data of surcharge incompleted.",
    "140025": "Configured data of surcharge incorrect.",
    "140026": "Area not found.",
    "140027": "Country data not found.",
    "140028": "Province/State data not found.",
    "140029": "City data not found.",
    "140030": "County data not found.",
    "140031": "Postcode prefix can not be empty.",
    "140032": "Area name is required.",
    "140033": "Country area can not be empty.",
    "140034": "Province/State area can not be empty.",
    "140035": "City area can not be empty.",
    "140036": "County area can not be empty.",
    "140037": "Postcode area can not be empty.",
    "140038": "Charges name can not be empty.",
    "140039": "Charges name is too long.",
    "140040": "Minimum days can not be less than zero.",
    "140041": "Maximum days must be more than zero and minimum days.",
    "140042": "Discount can not be less than zero.",
    "140043": "Fuel surcharge can not be less than zero.",
    "140044": "Volume weight must be more than zero.",
    "140045": "Volume share ratio can not be less than zero.",
    "140046": "Interval of carry weight must be more than zero.",
    "140047": "Minimum weight can not be less than zero.",
    "140048": "Maximum weight must be more than minimum weight.",
    "140049": "Minimum volume can not be less than zero.",
    "140050": "Maximum volume must be more than minimum volume.",
    "140051": "Minimum chargeable weight can not be less than zero.",
    "140052": "Name can not be empty.",
    "140053": "Code can not be empty.",
    "140054": "Warehouse can not be empty.",
    "140055": "Packaging info can not be empty.",
    "140056": "Product info can not be empty.",
    "140057": "Target address can not be empty.",
    "140058": "Only two decimal places supported for weight.",
    "140059": "Only two decimal places supported for length.",
    "140060": "Incorrect logistics charges(possible reason: oversize, channel unsupported, etc.)",
    "140061": "Logistics account name can not be empty.",
    "140062": "Logistics account type unsupported.",
    "140063": "Logistics account configurations can not be empty.",
    "140064": "Source address can not be empty.",
    "140065": "DHL & USPS currently unsupported.",
    "140066": "Account info doesn't exist.",
    "140067": "Creating logistics paper failed.",
    "140068": "International shipping currently supported.",
    "140069": "Oversize for Fedex: length+(width+height)*2 can not be more than 330CM",
    "140070": "Weight can not be more than 68kg for Fedex.",
    "140071": "Acquiring tracking info failed.",
    "140072": "Area Mark is required.",
    "140073": "Area Mark incorrect.",
    "140074": "Special Area unfound.",
    "140075": "Country of Logistics Plan is different with the one of Logistics Center.",
    "140076": "JSON data of the Account is incorrect.",
    "140077": "Logistics Channel of Logistics Account cannot be empty.",
    "140078": "Hand Over Type of Logistics Account cannot be empty.",
    "140079": "Built-in Channel unfound.",
    "140080": "Address Type of Embargo Settings of Logistics Solution is incorrect.",
    "140081": "Logistics Formula Mode incorrect.",
    "140082": "Discount must be more than 0%, less than or equal to 100%.",
    "140083": "Account Class unfound.",
    "140084": "Discount of the Acccount Class already existed.",
    "140085": "Discount Info unfound.",
    "140086": "Address Type of Limited-Shipping Settings of Logistics Solution is incorrect.",
    "140087": "Limited-Shipping Settings of Logistics Solution unfound.",
    "140088": "Embargo Settings of Logistics Solution unfound.",
    "140089": "Commission Ratio cannot be less than 0.",
    "140090": "VAT of Logistics Solution is required.",
    "140091": "Discount must be more than 0%, less than or equal to 100%.",
    "140092": "禁运配置处理中",
    "140093": "限运配置处理中",
    "140094": "计费方式错误",
    "140095": "接口账户必填",
    "150000": "Supplier Solution unfound.",
    "150001": "Country is incorrect of Supplier Solution.",
    "150002": "Province/State is incorrect of Supplier Solution.",
    "150003": "City is incorrect of Supplier Solution.",
    "150004": "County is incorrect of Supplier Solution.",
    "150005": "Charges Template unfound.",
    "150006": "Configuration Items of Charges Template cannot be empty.",
    "150007": "Initial Weight cannot be less than 0.",
    "150008": "Terminating Weight must be more than Initial Weight.",
    "150009": "First Weight charges cannot be less than 0.",
    "150010": "Additional Weight unit must be more than 0.",
    "150011": "Additional Weight unit price cannot be less than 0.",
    "150012": "Weight Section should be sequential.",
    "150013": "Weight cannot be less than 0.",
    "150014": "Charges cannot be less than 0.",
    "150015": "Charge Mode incorrect.",
    "150016": "Quantity cannot be less than 0.",
    "150017": "Quantity of each page must be more than 0.",
    "150018": "Weight Type incorrect.",
    "150019": "Initial Length cannot be less than 0.",
    "150020": "Terminating Length must be more than Initial Length.",
    "150021": "Charge Mode of surcharges is incorrect.",
    "150022": "Gap must be more than 0.",
    "150023": "Surcharge Configuration unfound.",
    "150024": "Surcharge Configuration data is incomplete.",
    "150025": "Surcharge Configuration data incorrect.",
    "150026": "Area not found.",
    "150027": "Country data not found.",
    "150028": "Province/State date not found.",
    "150029": "City data not found.",
    "150030": "County data not found.",
    "150031": "Postcode prefix cannot be empty.",
    "150032": "Area Name is required.",
    "150033": "Country field cannot be empty.",
    "150034": "Province/State field cannot be empty",
    "150035": "City field cannot be empty",
    "150036": "County field cannot be empty",
    "150037": "Postcode field cannot be empty",
    "150038": "Charges Name cannot be empty.",
    "150039": "Charges Name is over length.",
    "150040": "Minimal Days cannot be less than 0.",
    "150041": "Maximum Days must be more than 0 and Minimal Days.",
    "150042": "Discount cannot be less than 0.",
    "150043": "Fuel Surcharge cannot be less than 0.",
    "150044": "Volume Weight coefficient must be more than 0.",
    "150045": "Volume Share coefficient cannot be less than 0.",
    "150046": "Gap of Carry Weight must be more than 0.",
    "150047": "Minimal Weight cannot be less than 0.",
    "150048": "Maximum Weight must be more than Minimal Weight.",
    "150049": "Miniamal Volume cannot be less than 0.",
    "150050": "Maximum Volume must be more than Minimal Volume.",
    "150051": "Minimal Chargeable weight must be more than 0.",
    "150052": "Name cannot be empty.",
    "150053": "Code cannot be empty.",
    "150054": "Warehouse cannot be empty.",
    "150055": "Package Info cannot be empty.",
    "150056": "Product Info cannot be empty.",
    "150057": "Target Address cannot be empty.",
    "150058": "Only two decimal places supported for Weight.",
    "150059": "Only two decimal places supported for Length.",
    "150060": "Supplier Error.(Possible Cause:oversize, Channel unsupported, etc.)",
    "150061": "Api Account Name cannot be empty.",
    "150062": "Api Code Type unsupported.",
    "150063": "Api Account configuration cannot be empty.",
    "150064": "Sourcing Address cannot be empty.",
    "150065": "DHL and USPS unsupported currently.",
    "150066": "Account Info does not exist.",
    "150067": "Creating supplier waybill failed.",
    "150068": "International Shipping unsupported currently.",
    "150069": "Fedex Length and Girth oversize, length+(width+height)*2 must be less than 330CM",
    "150070": "Fedex Weight must be less than or equal to 68KG",
    "150071": "Acquiring Tracking Info from supplier failed.",
    "150072": "Area Mark is required.",
    "150073": "Area Mark incorrect.",
    "150074": "Special Area not found.",
    "150075": "Country of Supplier Solution is different with the one of Supplier Center.",
    "150076": "JSON data of the account is incorrect.",
    "150077": "Supplier Channel of Supplier Account cannot be empty.",
    "150078": "Hand Over Type of Supplier Account cannot be empty.",
    "150079": "Built-in Channel is not found.",
    "150080": "Address Type of Embargo Settings of Supplier Solution is incorrect.",
    "150081": "Formula Mode of Supplier is incorrect.",
    "150082": "Discount must be more than 0%, less than or equal to 100%.",
    "150083": "User Class not found.",
    "150084": "Discount of this User Class already exist.",
    "150085": "Discount Info not found.",
    "150086": "Address Type of Limited-Shipping Settings of Supplier Solution is incorrect.",
    "150087": "Limited-Shipping Settings of Supplier Solution not found.",
    "150088": "Embargo Settings of Supplier Solution not found.",
    "150089": "API category unsupported.",
    "150090": "The Name already exist in API Management.",
    "150091": "API Management not found.",
    "150092": "The Name already exist in API Configuration.",
    "150093": "API Configuration not found.",
    "150094": "ITDIDA Logistics Type unsupported.",
    "150095": "ITDIDA Duty Payment Type unsupported.",
    "150096": "Carrier Channel Code of Fedex/Ups/Idida/Elvish cannot be empty.",
    "150097": "ITDIDA account configuration incorrect.",
    "150098": "Channel cannot support neither Commercial Address nor Residential Address.",
    "150099": "Waybill not found.",
    "150100": "VIWO signature exception.",
    "150101": "VIWO data exception.",
    "150102": "17 signature exception.",
    "150103": "17 data exception.",
    "150104": "Commission ratio cannot be less than 0.",
    "150105": "Discount must be more than 0%, less than or equal to 100%.",
    "150106": "Solution has been configured.",
    "150107": "标签生成失败",
    "150108": "数据处理中",
    "150109": "API接口处理中",
    "150110": "API方案处理中",
    "150111": "禁运配置处理中",
    "150112": "限运配置处理中",
    "150113": "极兔快递MX只支持墨西哥",
    "150114": "极兔快递区县名称必须",
    "150115": "极兔快递的允许重量为10G到30000G 即0.01KG到30KG",
    "150116": "Accufrate获取费用失败",
    "150117": "计算方式错误",
    "150118": "接口账户必填",
    "160000": "Partner Group Name already exist.",
    "160001": "Partner Number already exist.",
    "160002": "Corresponding Solution not found.",
    "160003": "Group Info does not exist.",
    "160004": "Partner not found.",
    "160005": "Partner commission not found.",
    "160006": "Group commission not found.",
    "160007": "Commission record has been added, record cannot be repeated.",
    "160008": "The value of partner tag already exist.",
    "160009": "Without commission ratio settings.",
    "160010": "No unsettled commission.",
    "160011": "Settling amount must be more than 0.",
    "170001": "Application does not exist.",
    "170002": "Invalid Application Key.",
    "170003": "Application is not authorized by the store.",
    "170004": "Application is not authorized for this API by the store.",
    "170005": "应用还未设置所需权限",
    "170006": "授权项标识已经存在",
    "170007": "授权项名称已经存在",
    "170008": "授权项必填",
    "170009": "授权项不存在",
    "170010": "申请记录已审核",
    "170011": "授权项不在授权应用中",
    "170012": "应用授权签名不正确",
    "170013": "无效的返回地址",
    "900000": "产品数量必填",
    "900001": "配送订单第三面单必填",
    "900002": "配送订单第三方面单文件必传",
    "900003": "文件格式不正确",
    "900004": "配送订单赛易物流必填",
    "900005": "配送订单派送方式必填",
    "900006": "赛易物流vatcode必填",
    "900007": "仓库不存在",
    "900008": "产品信息不存在",
    "900009": "仓库库存不足",
    "900010": "配送订单包装材料必填",
    "900011": "配送订单包装材料不存在",
    "900012": "包装材料体积或容重不足",
    "900013": "国家不存在",
    "900014": "省份不存在",
    "900015": "城市不存在",
    "900016": "区县不存在",
    "900017": "仓库/地址不支持赛易物流",
    "900018": "暂不支持赛易物流",
    "900019": "付款异常",
    "900020": "余额不足",
    "900021": "透支额度不足",
    "900022": "不可重复支付",
    "900023": "不可重复退款",
    "900024": "退款失败",
    "900025": "公司不存在",
    "900026": "逾期未付",
    "900027": "配送订单不存在",
    "900028": "配送订单状态已改变",
    "900029": "授权应用不存在",
    "900030": "无效的应用密匙",
    "900031": "公司已经被冻结",
    "900032": "应用没有获得公司授权",
    "900033": "配送订单状态已取消",
    "900034": "订单id或订单号必填",
    "900035": "产品必填",
    "900036": "配送订单费用未设定",
    "900037": "应用已经被关闭",
    "900038": "产品名称已存在",
    "900039": "产品货号已存在",
    "900040": "运输特征必传",
    "900041": "产品货号不符合规则",
    "900042": "传入的标签识别码重复",
    "900043": "标签图片不可空",
    "900044": "物流类别错误",
    "900045": "派送方式错误",
    "900046": "上传图片失败",
    "900047": "标签图片法解析条码失败或格式错误",
    "900048": "标签不存在",
    "900049": "默认标签不可删除",
    "900050": "申报国家海关信息不完整",
    "900051": "当前国家不存在或未开通",
    "900052": "当前币种不存在或未开通",
    "900053": "包装袋仅支持一个产品",
    "900054": "包含电池电池信息必填",
    "900055": "存在非法运输特征",
    "900056": "电池成分不存在",
    "900057": "电池类型不存在",
    "900058": "非法标签Id",
    "900059": "输入的信息不完整",
    "900060": "发自地址或者收货地址除address2 和 区县英文名称外其他是必须的",
    "900061": "不支持的枚举类型",
    "900062": "数据处理中",
    "900063": "状态不允许操作",
    "900064": "异常状态与客户操作类型不匹配",
    "900065": "超过最大数量",
    "900066": "计划暂不支持",
    "900067": "正则不符合规则",
    "900068": "同一单只允许一种货币，海关申报只能有一种货币报价",
    "900069": "地址的行政上一级不可空",
    "900070": "上门揽件不支持",
    "900071": "包含电池，电池信息必填",
    "900072": "当前包裹已销毁",
    "900073": "卡车报价已过期",
    "900075": "当报价类型为WG时服务等级必须的",
    "900078": "当前退货计划产品FNSKU不可重复",
    "900079": "退货包裹编号已存在",
    "900080": "邮编不能为空",
    "900081": "详细地址不能为空",
    "900082": "当前产品已被使用不允许删除",
    "default": "Operation error, please try it again later"
  }
}